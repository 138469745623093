import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
// import NavBar from "../components/nav_bar"

import {
  Container,
  makeStyles,
  CssBaseline,
  Typography,
  Box,
  Breadcrumbs,
  Link,
} from "@material-ui/core"
import svgImgFtr from "../../static/media/Images/footerCamBtn.svg"

const windowGlobal = typeof window !== 'undefined' && window

const useStyles = makeStyles(theme => ({
  ftrmain: {
    backgroundImage: "radial-gradient(circle farthest-side,#00b1d5 2%, #005b7b 70%)",
    backgroundSize: "contain",
    paddingTop: "10em",
    position: "absolute",
    paddingBottom: "2em",
    marginTop: 74,
    width: "100%",
    left: 0,
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      bottom: "-150%",
    },
    [theme.breakpoints.down('xs')]: {
      bottom: -143,
    },
  },
  ftrdiv: {
    textAlign: "center",
  },
  ftrcpyright: {
    color: "#fff",
  },
  ftrSvg: {
    fill: "#fff"
  },
  svgContainerParent: {
    margin: "0 auto",
    height: "auto",
    textAlign: "center",
    width: 126,
    position: "absolute",
    zIndex: 2,
    left: "50%",
    bottom: 110,
    transform: "translate(-50%)",
    [theme.breakpoints.up('sm')]: {
      bottom: "57.3%",
    },
  },
  breadcrumbsPages: {
    backgroundColor: "rgba(255, 255, 255, 0.38)",
    width: "100%",
    padding: "10px 24px",
  }
}))

const AboutUsPage = ({ data }) => {
  const classes = useStyles()
  let lclLang = ''
  if (windowGlobal) {
    if (windowGlobal.localStorage.getItem('language')) {
      lclLang = windowGlobal.localStorage.getItem('language')
    } else {
      lclLang = navigator.language.substring(0, 2)
      windowGlobal.localStorage.setItem('language', lclLang)
    }
  }
  const langs = {}
  for (const value of data.allStrapiLangs.nodes) {
    langs[value.lang] = value
  }
  const [lang, setLang] = React.useState(lclLang)
  const locale = {}
  for (const value of data.allStrapiLocale.nodes) {
    let textObj = {}
    for (const value2 of value.text) {
      textObj[value2.lang.lang] = value2.text
    }
    locale[value.original] = textObj
  }
  const changeLang = (lang) => {
    setLang(lang)
    windowGlobal.localStorage.setItem('language', lang)
  }
  return (
    <Layout
      lang={lang}
      onLangChange={changeLang}
      locale={locale}
      className={classes.layoutContainer}
    >
      <CssBaseline />
      <Breadcrumbs className={classes.breadcrumbsPages}>
      <Link color="inherit" href="/">Home</Link>
      <Typography color="textPrimary">About Us</Typography>
      </Breadcrumbs>
      <Container maxWidth="sm" className={classes.pageContainer}>
      <h1>About</h1>
      <p>about us.</p>
      </Container>
      <footer className={`${classes.ftrmain} ftrmain`}>
        <div className={classes.svgContainerParent}>
          <img
            src={svgImgFtr}
            alt="test"
          />
        </div>
        <Box className={classes.ftrdivmain}>
          <Box className={classes.ftrdiv}>
            <Typography
              className={classes.ftrcpyright}
              component="span"
              variant="subtitle2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Tecvitli kuran. All Rights Reserved <svg className={classes.ftrSvg} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" style={{ verticalAlign: "middle" }}><path d="M10.08 10.86c.05-.33.16-.62.3-.87s.34-.46.59-.62c.24-.15.54-.22.91-.23.23.01.44.05.63.13.2.09.38.21.52.36s.25.33.34.53.13.42.14.64h1.79c-.02-.47-.11-.9-.28-1.29s-.4-.73-.7-1.01-.66-.5-1.08-.66-.88-.23-1.39-.23c-.65 0-1.22.11-1.7.34s-.88.53-1.2.92-.56.84-.71 1.36S8 11.29 8 11.87v.27c0 .58.08 1.12.23 1.64s.39.97.71 1.35.72.69 1.2.91 1.05.34 1.7.34c.47 0 .91-.08 1.32-.23s.77-.36 1.08-.63.56-.58.74-.94.29-.74.3-1.15h-1.79c-.01.21-.06.4-.15.58s-.21.33-.36.46-.32.23-.52.3c-.19.07-.39.09-.6.1-.36-.01-.66-.08-.89-.23-.25-.16-.45-.37-.59-.62s-.25-.55-.3-.88-.08-.67-.08-1v-.27c0-.35.03-.68.08-1.01zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg> {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </footer>
    </Layout>
  )
}
export default AboutUsPage

export const query = graphql`
  query AboutUsPage {
    site {
      siteMetadata {
        quranSiteUrl
        siteUrl
      }
    }
    allStrapiLocale {
      nodes {
        original
        text {
          lang {
            lang
          }
          text
        }
      }
    }
    allStrapiLangs {
      nodes {
        lang
        lang_name
        flag {
          publicURL
        }
      }
    }
  }
`